import "./styles/App.css";

import Bottles from "./assets/bottleImage.jpeg";
import Scenarie from "./assets/scenarie.jpeg";
import ForLogo from "./assets/for.png";
import FunLogo from "./assets/fun.png";
import Phone from "./assets/phone.png";
import WhatsApp from "./assets/whatsapp.png";
import TripAdvisorLogo from "./assets/tripadvisor.png";
import StarFilled from "./assets/star-filled.png";
import StarEmpty from "./assets/star-empty.png";
import Location from "./assets/location.png";
import Email from "./assets/email.png";
import Facebook from "./assets/facebook.png";
import Linkdin from "./assets/linkedin.png";
import Instagram from "./assets/instagram.png";
import Youtube from "./assets/youtube.png";
import MapImage from "./assets/map.png";
import NavLogo from "./assets/navbar.png";
import { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import emailjs from "@emailjs/browser";
function App() {
  const [leftBar, setLeftBar] = useState({
    phone: false,
    whatsapp: false,
    // tripAdvisor: false,
  });
  const aboutUsRef = useRef(null);
  const reviewRef = useRef(null);
  const contactRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const form = useRef();
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    // Basic email validation regex
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const email = form.current.email.value;
    const message = form.current.message.value;

    if (!validateEmail(email) || message.length <= 10) {
      setEmailError("Please enter a valid email address and write message");
      setTimeout(() => {
        setEmailError("");
      }, 8000);
      return;
    }

    emailjs
      .sendForm(
        "service_gv7t1u5",
        "template_b7ds7px",
        form.current,
        "9fl_1MewoYoi4N-0F"
      )
      .then(
        (result) => {
          form.current.reset();
        },
        (error) => {}
      );
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // let screenWidth = screen.width;
  function hideCopy() {
    setTimeout(() => {
      setLeftBar((prevLeftBar) => ({
        ...prevLeftBar,
        phone: false,
        whatsapp: false,
      }));
    }, 1000);
  }

  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      const reviewPosition = aboutUsRef.current.offsetTop;
      if (screenWidth < 991) {
        window.scrollTo({
          top: reviewPosition,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: reviewPosition - 180,
          behavior: "smooth",
        });
      }
    }
  };

  const scrollToReview = () => {
    if (reviewRef.current) {
      const reviewPosition = reviewRef.current.offsetTop;
      if (screenWidth < 991) {
        window.scrollTo({
          top: reviewPosition - 230,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: reviewPosition - 180,
          behavior: "smooth",
        });
      }
    }
  };
  const scrollToContact = () => {
    if (contactRef.current) {
      const reviewPosition = contactRef.current.offsetTop;
      if (screenWidth < 991) {
        window.scrollTo({
          top: reviewPosition - 230,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: reviewPosition - 180,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div className="App">
      {/* navigation */}
      <header className="App-header">
        <Navbar expand="lg" className="header-container">
          <Container>
            <Navbar.Brand href="#home">
              <div className="logo">
                <img src={ForLogo} />
                <img src={FunLogo} />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <img src={NavLogo} className="menu-tog" />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav " className="navbar-costum">
              <Nav className="me-auto">
                <div className="header-nav">
                  <button onClick={() => scrollToAboutUs()}>
                    <h3 className="header-text">about us</h3>
                  </button>

                  <button onClick={() => scrollToReview()}>
                    <h3 className="header-text">review</h3>
                  </button>

                  <button onClick={() => scrollToContact()}>
                    <h3 className="header-text">Contact</h3>
                  </button>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      {/* leftbar section  */}
      <div className="left-bar">
        <button
          className="left-bar-cont"
          onClick={() => {
            setLeftBar((prevLeftBar) => ({
              ...prevLeftBar,
              phone: true,
              whatsapp: false,
            }));
            navigator.clipboard.writeText("599920888");
            hideCopy();
          }}
        >
          <h3>{!leftBar.phone ? "599920888" : "copied"}</h3>
          <img src={Phone} />
        </button>
        <button
          className="left-bar-cont"
          onClick={() => {
            setLeftBar((prevLeftBar) => ({
              ...prevLeftBar,
              phone: false,
              whatsapp: true,
            }));
            navigator.clipboard.writeText("599920888");
            hideCopy();
          }}
        >
          <h3>{!leftBar.whatsapp ? "599920888" : "copied"}</h3>
          <img src={WhatsApp} />
        </button>
        <a
          href="https://www.tripadvisor.co.uk/AttractionProductReview-g294195-d25338124-Wine_Chasing_Private_Tour_From_Tbilisi_to_Kakheti-Tbilisi.html"
          target="_blank"
          className="left-bar-cont"
          // onClick={() => {
          //   setLeftBar((prevLeftBar) => ({
          //     ...prevLeftBar,
          //     phone: false,
          //     whatsapp: false,
          //     tripAdvisor: true,
          //   }));
          //   navigator.clipboard.writeText("tripAdvisor");
          //   hideCopy();
          // }}
        >
          <h3>tripAdvisor</h3>
          <img src={TripAdvisorLogo} />
        </a>
      </div>
      {/* image after navigation  */}
      <div className="image-container">
        {screenWidth <= 500 ? (
          <img src={require("./assets/background-mobile.png")} />
        ) : (
          <img src={require("./assets/bottleImage.jpeg")} />
        )}
      </div>
      {/* about us section  */}
      <div className="about-us-container">
        <div className="about-us" ref={aboutUsRef}>
          <div className="about-us-text">
            <h1>about us</h1>
            <p>
              Welcome to “For Fun Georgia” – Your Gateway to Adventure, Culture,
              and History!
              {/* <br /> <br /> */}
              At “For Fun Georgia,” we are passionate about introducing you to
              the hidden gems of this beautiful country. We take you on
              unforgettable journeys through Tbilisi, Mtskheta, Ananuri,
              Kazbegi, Borjomi, Batumi, Mestia, Kutaisi, and Martvili, where
              every step unveils a new chapter in Georgia’s rich history and
              culture.
              <br /> <br />
              Our Tours:
              <br /> • Embark on thrilling adventures as we explore the lush
              landscapes of Georgia, where the towering Caucasus Mountains meet
              the Black Sea.
              <br /> • Immerse yourself in the country’s vibrant history,
              discovering ancient sites and architectural marvels that have
              withstood the test of time.
              <br /> • Savor the unique flavors of Georgia with tasting sessions
              of organic wines and hands-on master classes in Georgian cuisine.
              <br /> • For the thrill-seekers, we offer exciting activities like
              karting and horseback riding, providing an adrenaline rush like no
              other.
              <br /> <br />
              Our Mission: Our mission is to open to our guests an unprecedented
              Georgia, a place where traditions meet modernity, nature’s beauty
              knows no bounds, and every moment is an adventure waiting to be
              discovered.
              <br /> <br />
              Come join us on a journey through Georgia’s heart and soul. Let
              “For Fun Georgia” be your guide to the extraordinary experiences
              this country has to offer.
            </p>
          </div>
          <img src={Scenarie} />
        </div>
      </div>
      {/* review section  */}
      <div className="review-container">
        <div className="review" ref={reviewRef}>
          <h3>Feedback</h3>
          <div className="costumer-review-cont">
            <h1>Our Costumers reviews</h1>
            <a
              target="_blank"
              href="https://www.tripadvisor.co.uk/AttractionProductReview-g294195-d25338124-Wine_Chasing_Private_Tour_From_Tbilisi_to_Kakheti-Tbilisi.html"
            >
              <h4 className="costumer-review">Leave us your review</h4>
            </a>
          </div>

          <div className="review-swiper">
            <Swiper
              slidesPerView={
                screenWidth < 1200 ? (screenWidth < 840 ? 1 : 2) : 3
              }
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="swiper-margin">
                  <div className="swip">
                    <div className="swip-inside">
                      <p>
                        We had an amazing experience with salomi.she was
                        enthusiastic in explaining about her country.She loves
                        her job.She explained a lot.she is fun too.She is ready
                        to customize it even after starting the journey.Very
                        flexible.Flexiblity is really helpful
                      </p>
                      <h4>Padmavati G</h4>
                      <div className="stars">
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-margin">
                  <div className="swip">
                    <div className="swip-inside">
                      <p>
                        Salu is a very good guide and knows what she is doing.
                        She has the ability to handle both small and big groups.
                        Fast decision making and knows what’s best for the
                        group. We went as a big group package and she managed
                        all of us and the tour beautifully.
                      </p>
                      <h4>Vishnu S</h4>
                      <div className="stars">
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-margin">
                  <div className="swip">
                    <div className="swip-inside">
                      <p>
                        We had an amazing day with Salome! She was knowledgeable
                        and was such an incredible tour guide. She made our trip
                        to Kakheti . We are so grateful for her and highly
                        recommend booking a day tour with Salome!
                      </p>
                      <h4>annie k</h4>
                      <div className="stars">
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-margin">
                  <div className="swip">
                    <div className="swip-inside">
                      <p>
                        Salome, the tour guide has good knowledge about the
                        locations, history of Georgia etc. She was freindly with
                        us. Specifically she was punctual with the time. We
                        enjoyed our trip in Georgia. Thanks to Salome for making
                        our trip memorable.
                      </p>
                      <h4>Nagaraj P</h4>
                      <div className="stars">
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-margin">
                  <div className="swip">
                    <div className="swip-inside">
                      <p>
                        Salome, Is one of the best travel Guide I ever come
                        across, Her knowledge on the city, Food and the way she
                        takes care of people is amazing, I was traveling with my
                        family Thanks Salome for making our trip such a
                        memorable one. Will never forget.
                      </p>
                      <h4>Leo S</h4>
                      <div className="stars">
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                        <img src={StarFilled} />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {/* contact section */}
      <div className="contact" ref={contactRef}>
        <h1>Contact us</h1>

        <div className="contact-main">
          <form className="input-container" ref={form} onSubmit={sendEmail}>
            <h4 className="error">{emailError}</h4>
            <input placeholder="name" name="name" className="input" />
            <div className="electronic-contact">
              <input placeholder="e-mail" name="email" />
              <input placeholder="phone" name="phone" />
            </div>
            <input
              placeholder="message"
              name="message"
              className="message-input"
            />
            <div className="submit-container">
              <button onClick={sendEmail}>Send</button>
              <div className="social-media">
                <h4>Follow us:</h4>
                {/* <a href="#">
                  <img src={Facebook} />
                </a>
                <a href="#">
                  <img src={Linkdin} />
                </a> */}
                <a
                  className="instagram-cont"
                  target="_blank"
                  href="https://www.instagram.com/4fungeorgia?igshid=M2RkZGJiMzhjOQ%3D%3D&utm_source=qr"
                >
                  <img src={Instagram} />
                </a>
                {/* <a href="#">
                  <img src={Youtube} />
                </a> */}
              </div>
            </div>
          </form>
          <div className="contact-info-container">
            {/* <div className="contact-info">
              <img src={Location} /> <h3>Address</h3>
            </div> */}
            <div className="contact-info">
              <img src={Phone} /> <h3>599920888</h3>
            </div>
            <div className="contact-info">
              <img src={Email} /> <h3>Salouchikhladze@gmail.com</h3>
            </div>
            {/* <div className="map">
              <a
                target="_blank"
                href="https://www.google.com/maps/dir/?api=1&destination=3+University+St,+T%27bilisi,+Georgia&destination_place_id=ChIJC3in_xJzREARpvjrdsdH6Ko&travelmode=driving"
              >
                <img src={MapImage} />
              </a>
            </div> */}
          </div>
        </div>
      </div>

      {/* footer section  */}
      <div className="footer  ">
        <p>© ForFun all rights reserved</p>
        <a href="https://proservice.ge/" target="_blank">
          <h4>Created by Proservice</h4>
        </a>
      </div>
    </div>
  );
}

export default App;
